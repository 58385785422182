import React from 'react';
import './component.css';
import CourseCard from '../CourseCard/Component';

function PricingPlans() {

    const handleClick = () => {
        window.location.href = 'tel:+919354705402';
    };

    const data = [
        {
            title: "Full Stack Web & Software Development With MERN",
            duration: "Duration : 5 - 6 Months",
            sub_title: "Technologies",
            module_key: ['Html', 'Css', 'Bootstrap', 'Javascript', 'Jquery', 'React Js', 'Node Js', 'Express Js', 'MongoDB'],
            know_more: 'Know More',
            image: 'img/book.png'
        },
        {
            title: "Website Frontend Designing (With React JS)",
            duration: "Duration : 3 - 4 Months",
            sub_title: "Technologies",
            module_key: ['Html', 'Css', 'Bootstrap', 'Javascript', 'Jquery', 'React Js'],
            know_more: 'Know More',
            image: 'img/book.png'
        },
        {
            title: "Website Backend Development (With Node JS)",
            duration: "Duration : 3 - 4 Months",
            sub_title: "Technologies",
            module_key: ['Javascript', 'Node Js', 'Express Js', 'MongoDB'],
            know_more: 'Know More',
            image: 'img/book.png'
        },
        {
            title: "Website Backend Development (With Php)",
            duration: "Duration : 2 - 3 Months",
            sub_title: "Technologies",
            module_key: ['Core Php', 'Mysql'],
            know_more: 'Know More',
            image: 'img/book.png'
        },
        {
            title: "Website Backend Development (Python)",
            duration: "Duration : 2 - 3 Months",
            sub_title: "Technologies",
            module_key: ['Python', 'Django', 'Flask', 'Mysql'],
            know_more: 'Know More',
            image: 'img/book.png'
        },
    ];

    return (
        <div className="container-fluid price-bck">
            <div className="row my_tflax">
                {data.map((course, index) => (
                    <CourseCard key={index} course={course} onKnowMoreClick={handleClick} />
                ))}
            </div>
        </div>
    );
}

export default PricingPlans;
