import React from "react";
import Navbar from "../Components/Navbar/Nav";
import Footer from "../Components/Footer/Footer";
import Section from "../Components/Section/Component";
import Section1 from "../Components/Section1/Component";
import Section2 from "../Components/Section2/Component";
import Section3 from "../Components/Section3/Component";
import Section4 from "../Components/Section4/Component";


function Home() {
  return (
   <>
    <Navbar/>
      <Section />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    <Footer/>
   
   </>
  );
}

export default Home;
