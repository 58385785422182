import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";

import './App.css';
function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
