import React from 'react';
import './component.css';

const Traning = () => {
  return (
   <div className="container-fluid why-bck">
       <div className="row">
         <div className="fls-strt">
           <div className="col-lg-6">
           
           <img src="img/_21A2796-Hi.jpg"alt="CodesAndNotes.co.in"className="img-fluid"></img>
           </div>
           
           <div className="col-lg-6">
             <div className="txt-padding">
             <p className="hed-ing_why">Why should you learn Web Development in 2024?</p>
             <p className="main-txt">Learning Full-Satck Web, Appliction And Software Development development is crucial for several reasons,
                each contributing to a broader understanding of technology and its applications:</p>
                <ul className="listyle">
                  <li className="why-txt"><b>Career Opportunities:</b> These fields offer diverse career paths, from front-end development 
                    (creating user interfaces) to back-end development (server-side logic and databases),
                     and full-stack development (handling both front-end and back-end).</li>
                     
                     <li className="why-txt"><b>Problem-Solving Skills:</b> Developers tackle complex challenges daily, honing problem-solving
                        abilities through coding, debugging, and optimizing solutions.</li>

                        <li className="why-txt"><b>Innovation and Creativity:</b> It fosters innovation by enabling the creation of new digital products, 
                          services, and experiences that improve efficiency, connectivity, and user satisfaction.</li>

                          
                </ul>

             </div>
           </div>
           </div>
       </div>
   </div>
  );
};

export default Traning;
