import React from 'react';
import './component.css';

const Traning = () => {
  return (
   <div className="container-fluid card_back">
       <div className="row">
           <div className="col-lg-4 col-md-6  col-sm-6">
               <div className="traning_card">
                   <center><img src="img/top-rated.png"alt="CodesAndNotes.co.in"className="img-fluid tranin-icon"></img></center>
                   <p className="card-heading">Top Rated Institute</p>
                   <p className="card-text-traning"> First-ever elite institute with a curriculum covering each and every topic in Full-Stack Web, Application, And Software Development Course.</p>

               </div>
           </div>
           <div className="col-lg-4 col-md-6  col-sm-6">
           <div className="traning_card">
                   <center><img src="img/presentation.png"alt="CodesAndNotes.co.in"className="img-fluid tranin-icon"></img></center>
                   <p className="card-heading">Industry Expert Trainers</p>
                   <p className="card-text-traning">Trainers with industry experience of 2+ years and who have trained more than 2 hundred students to achieve a prominent career.</p>

               </div>
           </div>
           <div className="col-lg-4 col-md-6  col-sm-6">
           <div className="traning_card">
                   <center><img src="img/infrastructure.png"alt="CodesAndNotes.co.in"className="img-fluid tranin-icon"></img></center>
                   <p className="card-heading">Well Equipped Infrastructure</p>
                   <p className="card-text-traning">Located at one of the prime co-working space in Faridabad, equipped with a learning-oriented infrastructure designed for real-time exposure.</p>

               </div>
           </div>
       </div>
   </div>
  );
};

export default Traning;
