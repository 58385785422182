import React from "react";
import Navbar from "../Components/Navbar/Nav";
import Section from "../Components/Section/Component";
import Footer from "../Components/Footer/Footer";

function Contact() {
  return (
   <>
    <Navbar/>
    <Section/>
    <Footer/>
   </>
  );
}

export default Contact;
