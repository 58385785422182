import React, { useState } from 'react';
import './component.css';

const Training = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    city: '',
    classType: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const formattedDate = currentDate.getFullYear() + '-' +
      ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
      ('0' + currentDate.getDate()).slice(-2);

    const object = {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      city: formData.city,
      classType: formData.classType,
      date: formattedDate
    };

    try {
      await fetch('https://script.google.com/macros/s/AKfycbyHXUCrNkk41I9RHwO-xL3u1FYajPA8KF5OWNPgyk-xNz_Lid86yHlz_8IWsKwRho7U/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(object),
        mode: 'no-cors'
      });
      alert("Thanks for connecting with us");
    } catch (error) {
      console.error(error);
    }

    setFormData({
      name: '',
      email: '',
      mobile: '',
      city: '',
      classType: ''
    });
  };

  return (
    <div className="container-fluid traning_back_img">
      {/* <Helmet>
        <title>Ranked #1 Web Development Course in Faridabad</title>
        <meta name="description" content="Unlock your future with expert training in full-stack web, application, and software development courses." />
      </Helmet> */}
      <div className="row">
        <div className="overlay_theme"></div>
        <div className="col-lg-8   frm_bck1">
          <h1 className="no-heading">Ranked #1 Web Development Course in Faridabad</h1>
          <p className="traning_heading">
            Unlock your future with expert training in <span className="traning_heading-clr">full-stack web, application, and software development </span>courses.
          </p>
          <button className="download-brocher">Download Brochure</button>
        </div>
        <div className="col-lg-4  frm_bck1">
          <div className="frm_bck">
            <p className="frm-txt">
              SIGN UP FOR FREE DEMO CLASS
            </p>
            <form onSubmit={handleSubmit}>
              <label className="lab-eel" htmlFor="name">Name*</label><br />
              <input
                type="text"
                placeholder="Enter Your Name"
                name="name"
                className="frm-field"
                value={formData.name}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="email">Email*</label><br />
              <input
                type="email"
                placeholder="Enter Your Email"
                name="email"
                className="frm-field"
                value={formData.email}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="mobile">Mobile No*</label><br />
              <input
                type="number"
                placeholder="Enter Your Mobile No"
                name="mobile"
                className="frm-field"
                value={formData.mobile}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="city">City*</label><br />
              <input
                type="text"
                placeholder="Enter Your City"
                name="city"
                className="frm-field"
                value={formData.city}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="classType">Class Type*</label><br />
              <select
                className="frm-field"
                name="classType"
                value={formData.classType}
                onChange={handleChange}
                required
              >
                <option disabled value="">--Select--</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </select><br />
              <center>
                <button type="submit" className="skd_btn" id="submit_btn">Submit</button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Training;
